<template>
  <div>
    <h4>
      <b-badge  v-if="status == 0" variant="success" >
      {{ $t("status") }} - {{ $t("Open") }}
    </b-badge>
  </h4>
  <h4>
    <b-badge variant="success" v-if="status == 1">
      {{ $t("status") }} - {{ $t("active_marketplace_offer") }}
   </b-badge>
   </h4>
   <h4>
    <b-badge variant="danger" v-if="status == 2 || status == 7">
      {{ $t("status") }} - {{ $t("rejected") }}
   </b-badge>
   </h4>
   <h4>
    <b-badge variant="secondary" v-if="status == 3">
      {{ $t("status") }} - {{ $t("scoring_required") }}
   </b-badge>
   </h4>
   <h4>
    <b-badge variant="success" v-if="status == 4">
      {{ $t("status") }} - {{ $t("scored") }}
   </b-badge>
   </h4>
   <h4>
    <b-badge variant="danger" v-if="status == 6">
      {{ $t("status") }} - {{ invoice.is_reverse == 1 ? $t("supplier_confirmation_required") : $t("debtor_confirmation_required") }}
   </b-badge>
   </h4>
   <h4>
    <b-badge variant="secondary" v-if="status == 5">
      {{ $t("status") }} - {{ $t("click_sell_invoice") }}
   </b-badge>
   </h4>
   <h4>
    <b-badge variant="danger" v-if="status == 8">
      {{ $t("status") }} - {{ $t("soon_goes_active") }}
   </b-badge>
   </h4>
    <h4>
      <b-badge  variant="success" v-if="status == 9">
      {{ $t("status") }} - {{ $t("in_review") }}
    </b-badge>
    </h4>
    <h4>
      <b-badge  variant="success" v-if="status == 10">
      {{ $t("status") }} - {{ $t("sold") }}
    </b-badge>
    </h4>
    <!-- <b-button variant="outline-success" v-if="status == 11">
      {{ $t("status") }} - {{ $t("invoice_payback") }}
    </b-button> -->
    <h4>
      <b-badge  variant="success" v-if="status == 11">
      {{ $t("status") }} - {{ $t("settled") }}
    </b-badge>
    </h4>
   <h4>
    <b-badge  variant="danger" v-if="status == 99">
      {{ $t("status") }} - {{ $t("removed") }}
    </b-badge>
    </h4>
  </div>
</template>

<script>
export default {
  name: "InvoiceStatus",
  props: ["status", "invoice"],
};
</script>

<style scoped>
</style>
